@import "../../scss/variables";
@import "../../scss/mixins";

.project-item {
  padding-top: 80px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  // padding-left: 80px !important;
  // padding-right: 80px !important;
  padding-bottom: 13px !important;
  @include to-lg {
    // padding: 50px 0 !important;
    padding-bottom: 40px !important;
  }
  h4 {
    color: $color_1;
  }
  a.project-link {
    color: $color_1;
    display: inline-block;
    margin-bottom: 20px !important;
    line-height: 16px !important;
    padding-bottom: 0;
    border-bottom: 1px solid $color_1;
    @include typography($weight: 600, $height: 24px);
    &:hover {
      color: darken($color_1, 15%);
      border-bottom-color: darken($color_1, 15%);
    }
  }
  p {
    color: rgba(255, 255, 255, 0.7);
  }
  .project-content-block {
    padding: 0 80px;
    @include to-lg {
      padding: 0 40px !important;
    }
  }
  .project-image-container {
    padding: 0 !important;
    @media screen and(min-width: 992px) {
      min-height: $main_page_slider_height;
    }
  }
}
