@import "../../../../scss/variables";
@import "../../../../scss/mixins";

.dropdown {
  position: relative;
}

.dropdown-menu {
  display: none;
  padding: 2px 0;
  position: absolute;
  z-index: 10;
  width: auto;
  min-width: 6.5rem;
  background-color: $bg_1;
  &.show {
    display: block;
  }
  border-radius: 0 0 4px 4px;
  box-shadow: 0px 14.0436px 28.0873px rgba(154, 170, 207, 0.15);
  background-clip: padding-box;
  border-top: 1px solid #FFFFFF40;
}

.dropdown-item a {
  display: block;
  width: 100%;
  white-space: nowrap;
  padding: 14px 21px 15px 18px;
  @include typography($size: 16px, $weight: 400, $style: normal, $height: 130%);
  color: $color_3;
  &:hover {
    font-weight: 500;
    background-color: darken($color: $bg_3, $amount: 2%);
    color: $color_2;
  }
}

.chevron-toggler {
  @include flex($display: inline-flex, $align: center);
  color: $color_3;
  height: 100%;
  white-space: nowrap;
  svg {
    fill: $color_1;
    width: 15px;
    margin-left: 3.5px;
    display: inline-block;
    transition: transform 0.3s ease-out;
  }
}

.chevron-toggler.rotate {
  svg {
    fill: $color_1;
    transform: rotate(180deg);
  }
}

.hover {
  color: $color_1 !important;
  background-color: $color_5;
  svg {
    fill: $color_1 !important;
  }
}