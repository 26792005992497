@import '../../scss/variables';
@import '../../scss/mixins';

.service-item {
  padding: 40px;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  a {
    border-bottom: 1px solid $color_2;
    font-weight: 600;
    max-width: 86px;
    &:hover {
      border-color: lighten($color_2, 15%);
    }
  }
  .service-item-content-block {
    width: 65%;
    flex: 0 0 auto;
    padding-left: 28px;
    p.pretty-formatted {
      margin-bottom: 25px;
      white-space: pre-wrap;
      .link {
        font-size: 18px;
        background-color: transparent;
        border: none;
        line-height: 0.7;
      }
    }
    p.text {
      margin-bottom: 25px;
      white-space: pre-wrap;
      height: 75px;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    pre {
      font-family: 'Gilroy';
      word-break: normal;
    }
    .service-item-content-block-text {
      height: 125px;
      margin-bottom: 15px;
      p {
        margin-bottom: 0;
      }
    }
  }
  .service-item-image-block {
    width: 35%;
    flex: 0 0 auto;
    img.img-cover {
      width: 100%;
    }
  }
  @include to-lg {
    flex-direction: column;
    margin-bottom: 24px;
    @include clean-service-item-padding;
  }
}