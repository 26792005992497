@import "../../scss/variables";
@import "../../scss/mixins";

.simple-carousel-container {
  padding: 0 !important;
  .dots {
    @include flex($align: center);
    margin-top: 25px;
  }
  @include to-lg {
    .arrow-wrapper-prev, .arrow-wrapper-next ,.arrow-left, .arrow-right {
      display: none;
    }
    .dots {
      margin-top: 20px;
    }
  }
}

.simple-carousel-wrapper {
  @include flex($align: center);
  position: relative;
  width: 100%;

  .arrow-wrapper-prev, .arrow-wrapper-next {
    position: absolute;
    background-color: $bg_1;
    height: 100%;
    display: inline-flex;
    justify-content: center;
    z-index: 2;
  }
  .arrow-wrapper-prev {
    left: -4%;
  } 
  .arrow-wrapper-next {
    right: -4%;
  }

  .arrow-wrapper-prev {
    @media screen and(min-width:1400px) and(max-width: 1410px) {
      left: -3%;
      .btn-arrow svg {
        width: 30px;
      }
    }
    @media screen and(min-width:1200px) and(max-width: 1230px) {
      left: -2%;
      .btn-arrow svg {
        width: 30px;
      }
    }

    @include media-lg {
      left: -4%;
    }

    @media screen and(min-width:992px) and(max-width: 1060px) {
      left: -5px;
      .btn-arrow svg {
        width: 15px;
      }
    }
  }

  .arrow-wrapper-next {
    @media screen and(min-width:1400px) and(max-width: 1410px) {
      right: -3%;
      .btn-arrow svg {
        width: 30px;
      }
    }

    @media screen and(min-width:1200px) and(max-width: 1230px) {
      right: -2%;
      .btn-arrow svg {
        width: 30px;
      }
    }

    @include media-lg {
      right: -4%;
    }
   
    @media screen and(min-width:992px) and(max-width: 1060px) {
      right: -5px;
      .btn-arrow svg {
        width: 15px;
      }
    }
  }
}

.simple-carousel-content-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.simple-carousel-content {
  display: flex;
  transition: transform 0.5s ease-in-out;
  -ms-overflow-style: none;  
  scrollbar-width: none;
  &.show-1 > * {
    width: 100%;
    flex-shrink: 0;
    flex-grow: 1;
  }
  &.show-2 > * {
    width: 50%;
    flex-shrink: 0;
    flex-grow: 1;
  }
  &.show-3 > * {
    width: calc(100% / 3);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.arrow-left {
  left: -4%;
}

.arrow-right {
  right: -4%;
}
