@import "../../../../scss/variables";

.offcanvas {
  background: url('../../../../images/ellipse_2_a.svg') left -250px top 100px / 873px 873px no-repeat, $bg_1;
}

.offcanvas-start {
  width: 100% !important;
}

.offcanvas .btn-close:focus {
  box-shadow: none;
}

.offcanvas-body {
  font-family: 'Gilroy';
  font-style: normal;
  .language-selector {
    margin-top: 15px;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    .language:first-of-type {
      padding-left: 0;
    }
    span:not(.language.active) {
      color: #676767AD;
    }
  }
  .navbar-nav .nav-link{
    color: $color_3;
    font-weight: 500;
    font-size: 22px;
    line-height: 130%;
    margin-bottom: 0 !important;
    padding: 15px 0;
  }
  .mobile-nav-group-toggler {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 8.5rem;
    button {
      width: 30px;
      padding: 0;
      border: 0;
      background: transparent;
      transition: all 0.3s ease-in-out;
      svg {
        fill: $color_3;
        transition: transform 0.3s ease-in-out;
      }
      svg.rotate {
        transform: rotate(-180deg);
      }
    }
  }
}