@import "../../scss//variables";
@import "../../scss/mixins";

.about-us{
  max-width: calc((100vw - 1320px) / 2 + 1320px - 15px);
  margin-left: auto;
  background-color: $bg_4;
  padding: calc(3 * $about_us_basic_padding)  calc(2 * $about_us_basic_padding);
  @include media-xs{
    margin-left: 15px;
    padding: calc(2 * $about_us_basic_padding) $about_us_basic_padding;
  }
  @include media-sm {
    max-width: calc((100vw - 540px) / 2 + 540px);
    padding: calc(2 * $about_us_basic_padding) $about_us_basic_padding;
  }
  @include media-md {
    max-width: calc((100vw - 720px) / 2 + 720px - 20px);
    padding: calc(2 * $about_us_basic_padding) $about_us_basic_padding;
  }
  @include media-lg {
    max-width: calc((100vw - 960px) / 2 + 960px - 15px);
  }
  @include media-xl {
    max-width: calc((100vw - 1140px) / 2 + 1140px - 15px);
  }
  h2 {
    color: $color_1;
  }
  p {
    color: $color_1;
  }
  a {
    color: $color_1;
    font-weight: 600;
    border-bottom: 1px solid $color_1;
    &:hover {
      color: darken($color_1, 15%);
      border-bottom-color: darken($color_1, 15%);
    }
  }
  .left-col {
    padding-right: calc(1.5 * $about_us_basic_padding);
    flex: 1 1 40%;
    @include to-lg {
      flex-basis: 100%;
      padding-right: 0;
    }
  }
  .right-col {
    flex: 1 1 60%;
    padding: 0 calc(1.5 * $about_us_basic_padding);
    @include to-lg {
      flex-basis: 100%;
      padding: 0;
      h2 {
        margin-bottom: 20px !important;
      }
    }
  }
  .image-wrapper{
    @media screen and (max-width: 991.98px) {
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 30px;
      width: 100%;
      height: auto;
    }
  }
}