@import "../../scss/variables";
@import "../../scss/mixins";

@mixin createLevel($prefix, $left) {
  .sl-level#{$prefix} {
    left: $left;
    @content;
  }
}

$image-width: 420;
$level-1-left: -$image-width * 0.04;
$level0-left: $image-width + $level-1-left;
$level1-left: $image-width * 2 + $level-1-left;

$image-width-xl: 360;
$level-1-left-xl: -$image-width * 0.04;
$level0-left-xl: $image-width-xl + $level-1-left-xl;
$level1-left-xl: $image-width-xl * 2 + $level-1-left-xl;

$image-width-lg: calc(1020px * 0.3);
$level-1-left-lg: -#{calc($image-width-lg * 0.03)};
$level0-left-lg: calc($image-width-lg + $level-1-left-lg);
$level1-left-lg: calc($image-width-lg * 2 + $level-1-left-lg);

$image-width-md: calc(100vw * 0.3);
$level-1-left-md: -#{calc(0.025 * 100vw)};
$level0-left-md: calc($image-width-md + $level-1-left-md);
$level1-left-md: calc($image-width-md * 2 + $level-1-left-md);

$image-width-sm: calc(100vw * 0.5);
$level-1-left-sm: calc(($image-width-sm / 2) * -1);
$level0-left-sm: calc($image-width-sm + $level-1-left-sm);
$level1-left-sm: calc($image-width-sm * 2 + $level-1-left-sm);

.endless-carousel {
  @include flex($align: center);
  position: relative;
  width: #{$image-width * 3}px;
  height: #{$image-width * 1.7}px;
  overflow: hidden;
  .endless-carousel-item {
    position: absolute;
    @include transition-list(('transform', 'left', 'opactity'));
    width: #{$image-width}px;
    height: auto;
    opacity: 0.8;
  }
  @include createLevel('-1', #{$level-1-left}px);
  @include createLevel('0', #{$level0-left}px ) {
    opacity: 1;
    transform: scale(1.1);
  }
  @include createLevel('1', #{$level1-left}px);
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  @include media-xl {
    width: #{$image-width-xl * 3}px;
    height: #{$image-width-xl * 1.7}px;
    .endless-carousel-item {
      width: #{$image-width-xl}px;
    }
    @include createLevel('-1', #{$level-1-left-xl}px);
    @include createLevel('0', #{$level0-left-xl}px);
    @include createLevel('1', #{$level1-left-xl}px);
  }
  @include media-lg {
    width: calc($image-width-lg * 3);
    height: calc($image-width-lg * 1.8);
    .endless-carousel-item {
      width: $image-width-lg;
    }
    @include createLevel('-1', $level-1-left-lg);
    @include createLevel('0', $level0-left-lg);
    @include createLevel('1', $level1-left-lg);
  }
  @media screen and(min-width: 576px) and(max-width: 991.98px){
    width: calc($image-width-md * 3);
    height: calc($image-width-md * 1.7);
    .endless-carousel-item {
      width: $image-width-md;
    }
    @include createLevel('-1', $level-1-left-md);
    @include createLevel('0', $level0-left-md);
    @include createLevel('1', $level1-left-md);
  }
  @include media-xs{
    width: calc($image-width-sm * 3);
    height: calc($image-width-sm * 2);
    .endless-carousel-item {
      width: calc($image-width-sm);
    }
    @include createLevel('-1', $level-1-left-sm);
    @include createLevel('0', $level0-left-sm);
    @include createLevel('1', $level1-left-sm);
  }
}

.right-enter {
  transform: translateX(-100%);
}

.right-enter-active {
  transform: translateX(0);
  @include transition-list('transform');
}

.right-exit {
  transform: translateX(0);
}

.right-exit-active {
  transform: translateX(100%);
  @include transition-list('transform');
}

.left-enter {
  transform: translateX(100%);
}

.left-enter-active {
  transform: translateX(0);
  @include transition-list('transform');
}

.left-exit {
  transform: translateX(0);
}

.left-exit-active {
  transform: translateX(-100%);
  @include transition-list('transform');
}