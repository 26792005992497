@import "../../scss/variables";
@import "../../scss/mixins";

footer {
  background-color: #FFFFFF;
  hr {
    margin-top: 87px;
    @include to-lg {
      margin-top: 50px;
    }
  }
}

footer .bottom-group-nav {
  @include flex($justify: space-between);
  @media screen and(max-width: 1199px) {
    flex-direction: column;
  }
}

footer .bottom-group-nav-info {
  @include flex($justify: space-between);
  flex: 1 1 auto;
  h4 {
    margin-bottom: 30px !important;
  }
  @media screen and(max-width: 1199px) {
    width: 100%;
  }
  @include to-lg {
    flex-direction: column;
    h4 {
      margin-bottom: 20px !important;
    }
  }
}

footer .contacts-list {
  li:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 18px !important;
  }
  a {
    color: $color_1;
    text-decoration: none;
    font-size: 18px !important;
    line-height: 26px;
    &:hover {
      color: $color_2;
    }
  }
  @media screen and (max-width: 991.98px) {
    font-size: 16px;
    a {
      font-size: 16px !important;
    }
  }
}

.contacts-list {
  list-style-type: none;
  padding-left: 0;
}

.footer-nav {
  @include flex($direction: column);
  @include to-lg {
    padding-bottom: 40px;
    a.nav-link {
      font-size: 16px;
    }
  }
  li:last-child a.nav-link {
    padding-bottom: 0 !important;
  }
}

.clip-path-top {
  clip-path: polygon(0% $clip, 100% 0%, 100% 100%, 0% 100%);
  transform: translateY(-(calc(2 * $clip)));
  padding-top: $clip;
}

.footer-bottom-group {
  background-color: $bg_2;
  color: $color_1;
  padding: 100px 0 0 0;
  a.nav-link {
    @include typography($size: 18px);
    color: $color_1;
    padding-left: 0;
    &:hover {
      color: $color_2;
    }
  }
}

.footer-brand {
  display: block;
  margin-bottom: 30px;
  flex-grow: 1;
}

.submit-request {
  padding: 0 0 50px 0 !important;
  .left-block {
    flex: 0 0 auto;
    width: 50%;
    padding-right: 100px;
  }
  .right-block {
    flex: 0 0 auto;
    padding: 0 75px 0 15px;
    width: 50%;
  }
  @include media-lg {
    .right-block {
      width: 55%;
      padding-right: 15px;
    }
    .left-block {
      width: 45%;
      padding-right: 50px;
    }
  }
  @include to-lg {
    padding-top: 70px !important;
    h2, p {
      margin-bottom: 30px !important;
    }
    .flex-container {
      flex-direction: column;
    }
    .right-block, .left-block {
      width: 100%;
      padding: 0;
    }
  }
}

.copyright {
  color: $color_1;
  @include flex;
  @include typography($size: 12px, $height: 194.3%, $style: normal, $weight: normal);
  letter-spacing: 0.05em;
  padding-top: 30px;
  padding-bottom: 24px;
}