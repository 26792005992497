@import "../../scss//mixins";
@import "../../scss/variables";

.custom-controls {
  @include flex($align: center);
  .carousel-control-next,
  .carousel-control-prev {
    display: block;
    position: initial;
    padding: 0;
    width: auto;
    @media screen and(max-width: 991.98px) {
      display: none;
    }
  }
  .carousel-control-prev {
    order: 1;
  }
  .carousel-inner {
    order: 2;
  }
  .carousel-control-next {
    order: 3;
  }
  .carousel-indicators {
    bottom: -60px;
    @media screen and(max-width: 991.98px) {
      bottom: -15%;
    }
    @media screen and(max-width: 767.98px) {
      top: 100vw * 0.75;
    }
    @media screen and(max-width: 569.98px) {
      top: calc((((100vw - 30px) / 2) * 2.1))
    }
  }
  @include carousel-indicators;
}