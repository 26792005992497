@import "../../scss/mixins";
@import "../../scss/variables";

.contacts {
  h4 {
    margin-bottom: 20px;
  }
  ul.contacts-list {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
  }
  li{
    @include typography($size: 16px, $height: 26px, $weight: normal, $style: normal);
    @include media-xs {
      font-size: 14px;
    }
  }
  a {
    color: $color_2;
    padding-left: 0;
    &:hover {
      color: lighten($color: $color_2, $amount: 15%);
    }
    @include media-xs {
      font-size: 14px !important;
    }
  }
  
  .flex-left {
    flex: 0 0 auto;
    width: 33%;
    padding: 0 40px;
    @include to-lg {
      width: 100%;
      padding-left: 0;
    }
  }
  
  .flex-right {
    flex: 0 0 auto;
    width: 66%;
    @include to-lg {
      width: 100%;
    }
  }
}

