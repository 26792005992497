.contact-us{
  display: flex;
  flex-direction: column;
  padding: 50px;
  box-shadow: 0px 7.39344px 21.6116px rgba(154, 170, 207, 0.15);
  @media screen and(max-width: 992px) {
    padding: 40px 20px 44px 20px;
  }
  .form-control-error {
    height: 20px;
    margin: 0;
    padding: 0;
    font-size: 14px;
    color: red !important;
  }
  input:not(.form-check-input) {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 149.4%;
    color: #5B5B5B;
    }
  .form-floating label {
    color: #A5A5A5;
    font-size: 16px;
  }
  input.form-check-input {
    border-radius: 4px;
    border: 1px solid #000000;
    box-sizing: border-box;
    margin-top: 0.15rem;
    &.is-invalid{
      border-color: #dc3545;
    }
    &:checked {
      background-color: #007DC3;
      border-color: #007DC3;
    }
    &:focus {
      box-shadow: none;
      outline: none !important;
    }
  }
  input.form-control {
    &:focus {
    box-shadow: none;
    outline: none !important;
    border-bottom: 1px solid #007DC3;
    }
  }
  a {
    color: #007DC3 !important;
    text-decoration: none;
    font-size: 14px;
    line-height: 149.4%;
  }
  label[for="privacy-policy"] {
    font-size: 14px;
    line-height: 149.4%;
    color: #252525;
  }
  button.btn {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    padding: 18px;
    &:disabled {
      background-color: rgb(172, 190, 223) !important;
      border: rgb(172, 190, 223) !important;
    }
  }
}
