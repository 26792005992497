@import "../../scss//variables";
@import "../../scss/mixins";

$sm-width: 540;
$md-width: 768;
$lg-width: 960;
$xl-width: 1140;
$xxl-width: 1320;

.mac-slider-wrapper {
  height: auto;
  background: url('./images/mackbook.png') center center/contain no-repeat;
}

.mac-slider {
  width: 100%;
  height: 100%;
  background: $bg_1;
  .carousel-inner {
    height: inherit;
  }
  .carousel-item {
    height: 100%;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .carousel-control-prev {
    left: -18%;
  }
  .carousel-control-next {
    right: -18%;
  }
  .carousel-indicators {
    bottom: -20%;
    @include media-md{
      bottom: -25%;
    }
    @include media-sm {
      bottom: -30%;
    }
    @include media-xs {
      bottom: -35%;
    }
  }
  @include carousel-indicators;
}

.responsive-size {
  $padding-top: #{$xxl-width * 0.061}px;
  $padding-horizontal: #{$xxl-width * 0.146}px;
  $padding-bottom: #{$xxl-width * 0.093}px;
  height: #{$xxl-width * 0.6}px;
  @include rewritePadding($padding-top, $padding-horizontal , $padding-bottom, $padding-horizontal);
  @include media-xs {
    $padding-top: calc(100vw * 0.024);
    $padding-horizontal: calc(100vw * 0.2);
    $padding-bottom: calc(100vw * 0.05);
    height: calc(100vw * 0.45);
    @include rewritePadding($padding-top, $padding-horizontal , $padding-bottom, $padding-horizontal);
  }
  @include media-sm {
    $padding-top: #{$sm-width * 0.024}px;
    $padding-horizontal: #{$sm-width * 0.203}px;
    $padding-bottom: #{$sm-width * 0.051}px;
    height: #{$sm-width * 0.45}px;
    @include rewritePadding($padding-top, $padding-horizontal , $padding-bottom, $padding-horizontal);
  }
  @include media-md {
    $padding-top: #{$md-width * 0.025}px;
    $padding-horizontal: #{$md-width * 0.172}px;
    $padding-bottom: #{$md-width * 0.052}px;
    height: #{$md-width * 0.45}px;
    @include rewritePadding($padding-top, $padding-horizontal , $padding-bottom, $padding-horizontal);
  }
  @include media-lg {
    $padding-top: #{$lg-width * 0.0615}px;
    $padding-horizontal: #{$lg-width * 0.146}px;
    $padding-bottom: #{$lg-width * 0.0925}px;
    height: #{$lg-width * 0.6}px;
    @include rewritePadding($padding-top, $padding-horizontal , $padding-bottom, $padding-horizontal);
  }
  @include media-xl {
    $padding-top: #{$xl-width * 0.061}px;
    $padding-horizontal: #{$xl-width * 0.146}px;
    $padding-bottom: #{$xl-width * 0.094}px;
    height: #{$xl-width * 0.6}px;
    @include rewritePadding($padding-top, $padding-horizontal , $padding-bottom, $padding-horizontal);
  }
}

.main-page-slider {
  $height: calc(0.525 * $main_page_slider_width);
  width: $main_page_slider_width;
  height: $height;
  padding-left: calc(0.153 * $main_page_slider_width) !important;
  padding-right: calc(0.15 * $main_page_slider_width) !important;
  padding-top: calc(0.0542 * $height);
  padding-bottom: calc(0.058 * $main_page_slider_width) !important;
  @include media-xs {
    $height: calc(0.525 * $main_page_slider_width_md);
    width: calc($main_page_slider_width_md - 30px);
    height: $height;
    padding-left: calc(0.13 * $main_page_slider_width_md) !important;
    padding-right: calc(0.13 * $main_page_slider_width_md) !important;
    padding-top: calc(0.07 * $height);
    padding-bottom: calc(0.05 * $main_page_slider_width_md) !important;
  }
  @include media-sm {
    $height: $main_page_slider_height_sm;
    width: calc($main_page_slider_width_md - 30px);
    height: $height;
    padding-top: calc(0.065 * $height);
    padding-left: calc(0.255 * $height) !important;
    padding-right: calc(0.255 * $height) !important;
    padding-bottom: calc(0.11 * $height) !important;
  }
  @include media-md {
    $height: $main_page_slider_height_md;
    width: $main_page_slider_width_md;
    height: $height;
    padding-top: calc(0.055 * $height);
    padding-left: calc(0.34 * $height) !important;
    padding-right: calc(0.34 * $height) !important;
    padding-bottom: calc(0.11 * $height) !important;
  }
  .mac-slider {
    background-color: transparent;
  }
  .carousel-inner {
    height: inherit;
    
  }
  .carousel-control-prev {
    left: -18%;
  }
  .carousel-control-next {
    right: -18%;
  }
  .btn-arrow svg.svg-arrow {
    width: 30px;
    fill: $color_1;
    opacity: 1;
  }
  .carousel-indicators {
    bottom:  - calc($height * 0.1 - 8px);
    @include media-xs {
      bottom: -30%;
    }
    @media screen and (min-width: 576px) and (max-width: 991.98px) {
      bottom: -11%;
    }
  }
}