@import "../../scss/variables";
@import "../../scss/mixins";

:root {
  --projects-height: 0;
}

main {
  background-color: $bg_1;
}

.video-container-positioned {
  position: absolute;
  width: 100%;
  height: $header_height_full;
  @media screen and(max-width: 992px) {
    height: $header_height_mobile;
  }

}

.video-container-overlay {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .video-safari {
    @include to-lg {
      @include flex($direction: row, $align: center, $justify: flex-start);
    }
  }
}

.video-overlay {
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0 , 0);
  opacity: 0.5;
  left: 0;
  top: 0;
}


.aiweapps-video {
  width: 100%;
  height: 100%;
  position: absolute;
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  -o-filter: blur(10px);
  -ms-filter: blur(10px);
  filter: blur(10px);
}

.video-pretty {
  object-fit: fill;
  @include to-lg {
    object-fit: cover;
  }
}

.video-safari {
  @include to-lg {
    height: $header_height_mobile;
    width: auto;
  }
}

.aiweapps-main {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $color_1;
  height: $header_height_full;
  background-color: black;
  @media screen and(max-width: 992px) {
    height: $header_height_mobile;
  }
  .presentation-main-group {
    transform: translate(0, 0);
    z-index: 5;
    p {
      display: inline-block;
      max-width: 595px;
      font-size: 20px;
      margin-bottom: 30px;
      color: $color_1;
    }
  }
  .link-as-button {
    padding: 18px 32px;
  }
}

.ellipses-background {
  padding-top: calc(80px + $clip);
  margin-bottom: -$clip;
  transform: translateY(-$clip);
  clip-path: polygon(0% 0%, 100% $clip, 100% 100%, 0% 100%);
  background:
    url('../../images/Group_723.svg') top -35vw left 37vw / calc(100vw / 1.59) 100vw no-repeat,
    url('../../images/Group_894.svg') top calc(var(--main-page-second-svg-offset-top) - calc(50vw * 0.69)) right 70vw / calc(100vw * 0.3) calc(100vw * 0.69)  no-repeat,
    url('../../images/Group_725.svg') top calc(var(--main-page-second-svg-offset-top) + 10vw) right 0 no-repeat,
    $bg_1;
  @include media-md {
    background:
    url('../../images/Group_723.svg') top -800px left 50% / 978px auto no-repeat,
    url('../../images/main_page_ellipse_md.svg') top calc(var(--main-page-second-svg-offset-top) - 4%) left 0  no-repeat,
    url('../../images/Group_725.svg') top calc(var(--main-page-second-svg-offset-top) + 300px) right 0 / 778px no-repeat,
    $bg_1;
  }
  @include to-md {
    background:
    url('../../images/Group_723.svg') top -800px left 50% / 978px auto no-repeat,
    url('../../images/main_page_ellipse_md.svg') top calc(var(--main-page-second-svg-offset-top) - 4%) left 0  no-repeat,
    url('../../images/Group_725.svg') top calc(var(--main-page-second-svg-offset-top) + 300px) left 25% / 778px no-repeat,
    $bg_1;
  }
} 

main .services.section {
  padding-top: 0;
  @include to-lg {
    .container {
      padding: 0;
    }
  }
}

.services-main-page-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .service-item-wrapper{
    border-radius: 4px !important;
    flex: 0 0 auto;
    width: 50%;
    &:hover {
      background-color: $color_1;
    }
    @include to-lg {
      width: 100%;
      h4 {
        margin-top: 20px !important;
      }
    }
    &:nth-child(2) {
      @media screen and (min-width: 1200px) {
        .service-item h4 {
          min-height: 62px;
        }
      }
    }
  }
  .service-item:last-of-type {
    margin-bottom: 0;
  }
}

main .projects {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
  @media screen and (max-width: 992px){
    padding-top: 0 !important;
  }
  h2 {
    color: $color_3;
    margin-bottom: 0 !important;
  }
}
