@import "../../../../scss/variables";
@import "../../../../scss/mixins";

.language-selector{
  @include flex($justify: flex-end, $align: center);
  margin-bottom: 0;
  span {
    padding: 0 0.25rem;
    @include typography($size: 18px, $height: 24px, $weight: 600);
    &.language:not(.active){
      &:hover {
        cursor: pointer;
        color: $color_2;
      } 
    }
    &.language.active{
      color: $color_2;
      pointer-events: none;
    }
  }
  @media screen and (max-width: 991.98px) {
    justify-content: flex-start;
  }
}
