@import "../../scss/variables";
@import "../../scss/mixins";

header {
  // position: absolute;
  // width: 100%;
  hr {
    margin: 0;
  }
}

header .language-selector {
  padding-left: 80px;
}

header.main {
  position: absolute;
  width: 100%;
  z-index: 3;
  .header-nav-item, hr {
    color: $color_1;
  }
  .language-selector span:not(.active) {
    color: #FFFFFFAD;
  }
  .navbar-toggler-custom {
    svg path {
      stroke: $color_1;
    }
  }
}

header.secondary {
  background-color: $bg_1;
  .header-nav-item {
    color: $color_6;
  }
  .language-selector span:not(.active) {
    color: #747474AD;
  }
  .chevron-toggler svg{
    fill: $color_3;
  }
  .chevron-toggler:hover svg {
    fill: $color_1;
  }
  hr {
    color: #BBBBBB;
  }
}


header.secondary.secondary-static {
  position: static;
}

.header-nav-item {
  padding: 1rem 15px;
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-decoration: none;
}

.header-nav-item:hover {
  background-color:  $color_5;
  color: $color_1 !important;
  box-shadow: 0px 14.0436px 28.0873px rgba(154, 170, 207, 0.15);
}

.navbar-toggler-custom {
  border: 0;
  box-shadow: 0;
  line-height: 1;
  width: 36px;
  background: transparent;
  padding-right: 0;
  svg path {
    stroke: $color_4;
  }
}

