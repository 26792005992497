@import "../../../../scss/variables";
@import "../../../../scss/mixins";

.breadcrumb {
  margin-top: 50px;
  margin-bottom: 0;
  list-style: none;
  @include flex($justify: start, $wrap: wrap);
}

.breadcrumb-item {
  font-size: 18px;
  a {
    color: $color_4 !important;
  }
  &.active {
    color: $color_2 !important;
    font-weight: 600;
  }
  &:hover {
    &:not(.active):after {
      content: "/";
      color: $color_2;
    }
    a {
      color: $color_2 !important;
    }
  }
  &:not(.active):after {
    padding: 0 20px;
    content: "/";
  }
}